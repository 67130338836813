<template>
    <BT-Blade-Item
        :bladesData="bladesData"
        bladeName="journey-template-pointers"
        :bladesToClear="['route-pointer-paths']"
        :canEdit="false"
        :canMinimize="false"
        :canPin="false"
        hideBackButton
        :loadingMsg="loadingMsg"
        navigation="journey-templates"
        startEditing
        title="Template"
        :actualUsedHeight="112"
        :defaultBladeWidth="350">
        <!--:itemID="id"-->
        <template v-slot="{ item }">
            <BT-Field-String
                v-model="item.journeyName"
                isEditing
                label="Journey Name" />
            
            <BT-Snack v-model="msg" />

            <v-bottom-navigation grow>
                <v-edit-dialog class="my-auto py-auto">
                    <v-btn title="Max Weight" class="my-auto py-auto">
                        <span>{{ item.maxWeight | toDisplayNumber }}</span>
                        <v-icon small>mdi-weight-kilogram</v-icon>
                    </v-btn>
                    <template v-slot:input>
                        <v-text-field v-model.number="item.maxWeight" hide-details type="number" />
                    </template>
                </v-edit-dialog>
                <v-edit-dialog>
                    <v-btn title="Max Packages">
                        <span>{{ item.maxPackages | toDisplayNumber }}</span>
                        <v-icon small>mdi-cube</v-icon>
                    </v-btn>
                    <template v-slot:input>
                        <v-text-field v-model.number="item.maxPackages" hide-details type="number" />
                    </template>
                </v-edit-dialog>
                <v-edit-dialog>
                    <v-btn title="Max Destinations">
                        <span>{{ item.maxDestinations | toDisplayNumber }}</span>
                        <v-icon small>mdi-truck</v-icon>
                    </v-btn>
                    <template v-slot:input>
                        <v-text-field v-model.number="item.maxDestinations" hide-details type="number" />
                    </template>
                </v-edit-dialog>
            </v-bottom-navigation>

            <v-expansion-panels v-if="item.id != null" v-model="panelV">
                <BT-Blade-Expansion-Items
                    canDelete
                    :canExportCSV="false"
                    :canRefresh="false"
                    :canSelect="false"
                    :items="pointers"
                    navigation="journey-pointers"
                    :onFilter="pointers => { return filterPointers(pointers, item) }"
                    title="Coverage">
                    <template v-slot:actions="{ open }">
                        <BT-Menu
                            v-if="open"
                            icon="mdi-plus"
                            :offset-x="false"
                            openOnHover
                            small
                            title="More Coverage">
                            <template v-slot>
                                <v-list-item @click.stop="addJourneyPointer(item, 'boundary')">
                                    <v-list-item-content>New Boundary</v-list-item-content>
                                </v-list-item>
                                <v-list-item @click.stop="addJourneyPointer(item, 'location')">
                                    <v-list-item-content>Add My Location</v-list-item-content>
                                </v-list-item>
                                <v-list-item @click.stop="addJourneyPointer(item, 'courier')">
                                    <v-list-item-content>Add Courier Location</v-list-item-content>
                                </v-list-item>
                                <v-list-item @click.stop="addJourneyPointer(item, 'customer')">
                                    <v-list-item-content>Add Customer Location</v-list-item-content>
                                </v-list-item>
                            </template>
                        </BT-Menu>
                    </template>
                    <template v-slot:everything="{ items, deleteItem, save }">
                        <v-list>
                            <v-list-item-group>
                                <drop-list
                                    accepts-type="customer"
                                    @insert="d => { insertCustomerLocation(d.data, item.id) }"
                                    @reorder="$event.apply(items)"
                                    :items="items"
                                    style="min-height: 100px;">
                                    <template v-slot:item="da">
                                        <v-list-item dense :key="da.index" @click="selectPointer(da.item)">
                                            <v-list-item-avatar class="mx-0 px-0">
                                                <v-slide-x-transition hide-on-leave>
                                                    <v-btn v-if="da.item.id == null || $BlitzIt.tracker.isChanged(da.item)" icon @click.stop="save(da.item)">
                                                        <v-icon>mdi-content-save</v-icon>
                                                    </v-btn>
                                                    <v-btn v-else small icon @click.stop="da.item.showArea = !da.item.showArea" title="Show Area">
                                                        <v-icon small :color="da.item.showArea && containsWeekday(da.item.weekdays, currentWeekday) ? da.item.color : 'grey'">mdi-circle</v-icon>
                                                    </v-btn>
                                                </v-slide-x-transition>
                                            </v-list-item-avatar>
                                            <v-list-item-content>
                                                <v-list-item-title v-if="da.item.location == null">
                                                    <v-text-field
                                                        v-if="currentPointer === da.item && da.item.location == null"
                                                        @click.stop
                                                        hide-details
                                                        single-line
                                                        v-model="da.item.pointerName"
                                                        label="Pointer Name" />
                                                    <span v-else-if="da.item.location == null">{{ da.item.pointerName }}</span>
                                                    <span v-else-if="da.item.location.companyAccount != null">{{ da.item.location.companyAccount.companyName }}</span>
                                                </v-list-item-title>
                                                <v-list-item-subtitle v-if="da.item.location != null">{{ da.item.location | toFamiliarLocationLine }}</v-list-item-subtitle>
                                                <v-list-item-subtitle>
                                                    <BT-Chips-Menu
                                                        v-model="da.item.weekdays"
                                                        :canOpen="currentPointer === da.item"
                                                        column
                                                        isEditing
                                                        :items="weekdayOptions()"
                                                        multiple
                                                        returnCSV
                                                        xSmall />
                                                </v-list-item-subtitle>
                                            </v-list-item-content>
                                            <v-list-item-action>
                                                <v-row>
                                                    <v-btn v-if="currentPointer === da.item && da.item.boundary != null" small icon @click.stop="$emit('match')" title="Match Area">
                                                        <v-icon small>mdi-vector-selection</v-icon>
                                                    </v-btn>
                                                    <v-btn v-if="da.item.locationID == null" small icon @click="copyPointer(da.item)" title="Duplicate">
                                                        <v-icon small>mdi-content-copy</v-icon>
                                                    </v-btn>
                                                    <v-btn small icon @click="deletePointer(da.item, deleteItem)">
                                                        <v-icon small class="error--text">mdi-delete</v-icon>
                                                    </v-btn>
                                                </v-row>
                                            </v-list-item-action>
                                        </v-list-item>
                                    </template>
                                    <template v-slot:inserting-drag-image="{ data }">
                                        <div>{{ data }}</div>
                                    </template>
                                    <template v-slot:reordering-drag-image />
                                    <template v-slot:feedback="{ data }">
                                        <v-skeleton-loader
                                        type="list-item-avatar-three-line"
                                        :key="data.id" />
                                    </template>
                                </drop-list>
                            </v-list-item-group>
                        </v-list>
                    </template>
                </BT-Blade-Expansion-Items>
            </v-expansion-panels>
        </template>
    </BT-Blade-Item>
</template>

<script>
import { DropList } from "vue-easy-dnd";
import { firstBy } from 'thenby';
import { getColors } from '~helpers';

export default {
    name: 'Journey-Template-Pointers-Blade',
    components: {
        BTMenu: () => import('~components/BT-Menu.vue'),
        BTChipsMenu: () => import('~components/BT-Chips-Menu.vue'),
        DropList,
    },
    data: function() {
        return {
            colors: getColors(),
            currentPointer: null,
            loadingMsg: null,
            msg: null,
            panelV: 0,
            // weekdayOptions: ['Always', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
        }
    },
    props: {
        bladesData: {
            type: Object,
            default: null
        },
        currentWeekday: null,
        pointers: null
    },
    methods: {
        async addJourneyPointer(template, type) {
            var location = null;
            if (type == 'location') {
                location = await this.$selectItem({ 
                    navigation: 'locations', 
                    itemText: 'locationName'
                });

                if (location == null) {
                    return;
                }
            }
            else if (type == 'customer') {
                var c = await this.$selectItem({ navigation: 'customers', itemText: 'buyer.companyName', params: { properties: 'ID,Buyer' } });
                if (c == null) {
                    return;
                }
                
                var policy = await this.$BlitzIt.store.get('suppliers', c.id, null, false, null, '/Locations/Locations');
                if (policy.possibleLocations.length > 1) {
                    location = await this.$selectItemFrom({ items: policy.possibleLocations, textFilter: 'toLocationLine' });
                }
                else if (policy.possibleLocations.length == 1) {
                    location = policy.possibleLocations[0];
                }
                else {
                    return;
                }
            }
            else if (type == 'courier') {
                // var co = await this.$selectItem({ navigation: 'couriers', itemText: 'seller.companyName', params: { properties: 'ID,Seller' } });
                // if (co == null) {
                //     return;
                // }
                var courierLocations = await this.$BlitzIt.store.getAll('couriers', null, false, null, '/getAll/CourierLocations');
                
                location = await this.$selectItemFrom({ items: courierLocations, textFilter: 'toFamiliarLocationLine' });
                
                if (location == null) {
                    return;
                }
            }

            var newPointer = {
                id: null,
                rowVersion: null,
                pointerName: location == null ? 'Area ' + (this.pointers.length + 1).toString() : null,
                boundary: location == null ? this.getAreaAround(template.startLocation, 0.5) : null,
                weekdays: this.currentWeekday,
                journeyTemplateID: template.id,
                locationID: location != null ? location.id : null,
                location: location != null ? location : null
            };

            newPointer = this.$BlitzIt.tracker.track(newPointer, {
                trackProps: ['id', 'rowVersion', 'boundary', 'locationID', 'pointerName', 'weekdays'],
                assignIgnored: { showArea: true, color: this.colors[10] }
            });

            this.pointers.unshift(newPointer);
        },
        copyPointer(pointer) {
            var newPointer = {
                id: null,
                rowVersion: null,
                pointerName: pointer.pointerName + ' - Copy',
                boundary: pointer.boundary,
                weekdays: pointer.weekdays,
                journeyTemplateID: pointer.journeyTemplateID,
                locationID: null,
                location: null
            };
            
            newPointer = this.$BlitzIt.tracker.track(newPointer, {
                trackProps: ['id', 'rowVersion', 'boundary', 'locationID', 'pointerName', 'weekdays'],
                assignIgnored: { showArea: true, color: this.colors[10] }
            });

            this.pointers.unshift(newPointer);
        },
        async deletePointer(pointer, deleteCallback) {
            if (pointer.id != null) {
                await deleteCallback(pointer);
            }

            var ind = this.pointers.findIndex(x => x === pointer);
            if (ind >= 0) {
                this.pointers.splice(ind, 1);
            }
        },
        filterPointers(pointers, template) {
            var l = template != null && template.id != null ? pointers.filter(z => z.journeyTemplateID == template.id) : [];
            l.sort(
                firstBy(x => x.locationID != null)
                .thenBy(x => x.location != null && x.location.companyAccount != null ? x.location.companyAccount.companyName : x.pointerName));
            return l;
        },
        async insertCustomerLocation(customer, templateID) {
            if (customer != null) {
                if (customer.locations == null) {
                    try {
                        customer.isLoading = true;
                        //pull locations
                        var policy = await this.$BlitzIt.store.get('suppliers', customer.id, null, false, null, '/Locations/Locations');
                        customer.locations = policy.possibleLocations.map(x => { return Object.assign({}, { ...x, showArea: false, companyAccount: customer.buyer }) });
                    }
                    finally {
                        customer.isLoading = false;
                    }
                }

                if (this.isLengthyArray(customer.locations)) {
                    var l = customer.locations[0];

                    if (customer.locations.length > 1) {
                        l = await this.$selectItemFrom({ 
                            items: customer.locations, 
                            textFilter: 'toLocationLine', 
                            label: 'Locations'
                        });
                    }

                    if (l != null) {
                        if (!this.pointers.some(x => x.locationID == l.id)) {
                            //can insert
                            try {
                                this.loadingMsg = 'Inserting';

                                l.companyAccount = customer.buyer;
                                var newPointer = {
                                    id: null,
                                    rowVersion: null,
                                    pointerName: null,
                                    boundary: null,
                                    weekdays: this.currentWeekday,
                                    journeyTemplateID: templateID,
                                    locationID: l.id,
                                    location: l,
                                }

                                var res = await this.$BlitzIt.store.post('journey-pointers', newPointer);

                                newPointer.id = res.id;
                                newPointer.rowVersion = res.rowVersion;

                                this.$BlitzIt.tracker.track(newPointer, { 
                                    trackProps: ['id', 'rowVersion', 'boundary', 'pointerName', 'locationID', 'weekdays'],
                                    assignIgnored: { showArea: true }
                                });
                                
                                this.pointers.push(newPointer);
                            }
                            catch (err) {
                                this.msg = this.extractErrorDescription(err);
                            }
                            finally {
                                this.loadingMsg = null;
                            }
                        }
                    }
                }
            }
        },
        selectPointer(item) {
            this.currentPointer = item;
            this.$emit('select', item);
        }
    }
}
</script>